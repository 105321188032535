.background {
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  overflow-y: hidden;
  position: relative;
}

.faq_logo {
  width: 25%;
  height: auto;
}

.background_bottom {
  position: absolute;
  bottom: -5%;
  width: 100%;
}

.faq_menu {
  border-radius: 24px;
  border: 1px solid rgba(18, 121, 16, 0.53);
  background: #fff;
  box-shadow: -1px 11px 29px 5px rgba(18, 121, 16, 0.11);
}

.faq_menu_title {
  font-size: 1.3rem;
  font-weight: 700;
}

.faq_menu_icon {
  border-radius: 17px;
  border: 1px solid rgba(18, 121, 16, 0.1);
  background: #1279102c;
  box-shadow: -1px 11px 29px 5px rgba(18, 121, 16, 0.11);
  cursor: pointer;
  transition: all ease 0.2s;
}

.faq_menu_icon:active,
.faq_menu_icon:hover {
  background: transparent;
}

.faq_menu_elements {
  display: block;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.faq_menu_elements_open {
  max-height: 1000px;
  opacity: 1;
}

.faq_menu_elements_close {
  max-height: 0;
  opacity: 0;
}

.faq_text_section_question,
.faq_text_section_answer {
  font-family: "Roboto", sans-serif;
}

.faq_text_section_title {
    font-weight: bold;
    margin: 2rem 0;
}

.faq_text_section_question {
    font-weight: 900;
    font-size: 1.1rem;
}

.faq_text_section_answer {
    font-size: .8rem;
    font-weight: 700;
}