@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Raleway !important;
}
