@media (min-width: 1440px) {
  .register_form_container {
    width: 50%;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .register_form_container {
    width: 75%;
  }
}

.register_form_container {
  border-radius: 52px;
  border: 1px solid rgba(18, 121, 16, 0.53);
  background: #fff;
  box-shadow: -1px 29px 42px 10px rgba(18, 121, 16, 0.11);
  margin: 0 auto;
}

.register_container {
  min-height: 100vh;
}

.register_form {
  width: 75%;
  margin: 0 auto;
}

.input-group {
  border-radius: 30px;
  margin: 1rem 0;
}

.input-group-text {
  background-color: transparent !important;
  border-right: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 1rem;
}

.form-control,
.form-select {
  border-left: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.register_form_button {
  border-radius: 100px;
  background: #127910;
  box-shadow: 0px 13px 10px 0px rgba(18, 121, 16, 0.14);
  width: 75%;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  padding: 0.7em 0;
  text-transform: uppercase;
}

.register_form_text {
  font-size: 0.7rem;
}

.form-control {
  outline: none;
  box-shadow: none;
  border-color: #dee2e6;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  border-color: #127910;
  box-shadow: 0 0 0 0.25rem rgba(18, 121, 16, 0.25);
}

.form-group {
  position: relative;
  transition: border-color 0.3s ease;
}

.form-group::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group:focus-within::before {
  border-color: #127910;
  box-shadow: 0 0 0 0.25rem rgba(18, 121, 16, 0.25);
}

.region_select_box {
  border-radius: 30px;
  border-color: #dee2e6;
  border-width: 2px;
  border-style: solid;
}

.region_select_box_green {
  border-radius: 30px;
  border-color: #127910;
  border-width: 2px;
  border-style: solid;
  box-shadow: 0 0 0 0.25rem rgba(18, 121, 16, 0.25);
}

.region_select_initial_trigger {
  background-color: #1279102f;
  padding: 2px 5px;
  border-radius: 50%;
  cursor: pointer;
}

.region_select_option p {
  margin: 0;
}

.region_select_options {
  text-align: start;
  font-size: 0.8rem;
  border-top: 1px #1279102f solid;
}

.region_select_option {
  border-radius: 30px;
  padding: 0.2rem 0.5rem;
  font-weight: bold;
}

.region_select_option:hover {
  background-color: #1279102f;
  color: #127910;
  cursor: pointer;
}

.region_select_options {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.region_select_options_open {
  max-height: 1000px;
  opacity: 1;
}

.region_select_options_close {
  max-height: 0;
  opacity: 0;
}
