@media (max-width: 768px) {
  .home_container {
    background-image: url("../../assets/home_banner_2.webp");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 90vh;
    position: relative;
    overflow: hidden;
    padding: 0 !important;
    background-color: black;
  }
}

@media (min-width: 768px) {
  .home_container {
    background-image: url("../../assets/home_banner_1.webp");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 0 !important;
  }
}

/* .home_container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(0, 0, 0, 1) 100%
  );
} */

@media (min-width: 1024px) {
  .home_text_logo {
    width: 50%;
    margin: 0 auto;
  }
}

.home_text {
  width: 50%;
}

.home_text_content h1 {
  font-weight: 700;
  color: white;
}

.home_text_content p {
  font-weight: 400;
  color: white;
  font-size: 1.5rem;
  width: 65%;
  margin: 0 auto;
}

.text-green {
  color: #15d211;
}

.home_images {
  width: 100%;
  height: 45%;
  position: relative;
}

.home_images_container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (min-width: 1440px) {
  .home_image_side {
    width: 15%;
  }

  .home_image_center {
    width: 25%;
    position: relative;
  }

  .home_text {
    width: 50%;
  }

  .home_text_content h1 {
    font-weight: 700;
    color: white;
  }

  .home_text_content p {
    font-weight: 400;
    color: white;
    font-size: 1.5rem;
    width: 65%;
    margin: 0 auto;
  }

  .home_bottom {
    position: absolute;
    bottom: -10%;
    width: 100vw;
    z-index: 100;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .home_text {
    width: 60%;
  }

  .home_text_content h1 {
    font-weight: 700;
    font-size: 2rem;
    color: white;
  }

  .home_text_content p {
    font-weight: 400;
    color: white;
    font-size: 1.3rem;
    width: 65%;
    margin: 0 auto;
  }

  .home_image_side {
    width: 17%;
  }

  .home_image_center {
    width: 27%;
    position: relative;
  }

  .home_bottom {
    position: absolute;
    bottom: -10%;
    width: 100vw;
    z-index: 100;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home_text {
    width: 60%;
  }

  .home_text_content h1 {
    font-weight: 700;
    font-size: 1.5rem;
    color: white;
  }

  .home_text_content p {
    font-weight: 400;
    color: white;
    font-size: 1rem;
  }

  .home_image_side {
    width: 25%;
  }

  .home_image_center {
    width: 35%;
    position: relative;
  }

  .home_bottom {
    position: absolute;
    bottom: -5%;
    width: 100vw;
    z-index: 100;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .home_text {
    width: 80%;
  }

  .home_text_content h1 {
    font-weight: 700;
    font-size: 1.7rem;
    color: white;
  }

  .home_text_content p {
    font-weight: 400;
    color: white;
    font-size: 1.2rem;
  }

  /* .home_image_side {
    width: 25%;
  }

  .home_image_center {
    width: 35%;
    position: relative;
  } */

  .home_images_container {
    display: none;
  }

  .home_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
  }

  .home_text_content p {
    width: 100%;
  }

  .home_container {
    position: relative;
  }

  .home_bottom2 {
    position: absolute;
    bottom: 0%;
    width: 100vw;
    z-index: 100;
    padding: 0;
    margin: 0;
  }
}

.home_image_center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #14d2113e;
  left: 0;
}

.home_image_side {
  position: relative; /* Ensure proper positioning for the shadow */
}

.home_image_side::after {
  content: ""; /* Create a pseudo-element for the shadow */
  position: absolute; /* Position the shadow */
  top: 0; /* Adjust the shadow's vertical position */
  left: 0; /* Adjust the shadow's horizontal position */
  width: 100%; /* Make the shadow cover the entire div */
  height: 100%; /* Make the shadow cover the entire div */
  box-shadow: 0px -115px 49px -10px rgba(255, 255, 255, 0.86) inset;
  -webkit-box-shadow: 0px -115px 49px -10px rgba(255, 255, 255, 0.86) inset;
  -moz-box-shadow: 0px -115px 49px -10px rgba(255, 255, 255, 0.86) inset;
  z-index: 100; /* Position the shadow behind the content */
}

.home_image_center {
  position: relative; /* Ensure proper positioning for the shadow */
}

.home_image_center::before {
  content: ""; /* Create a pseudo-element for the shadow */
  position: absolute; /* Position the shadow */
  top: 0; /* Adjust the shadow's vertical position */
  left: 0; /* Adjust the shadow's horizontal position */
  width: 100%; /* Make the shadow cover the entire div */
  height: 100%; /* Make the shadow cover the entire div */
  box-shadow: 0px -115px 49px -10px rgba(255, 255, 255, 0.86) inset;
  -webkit-box-shadow: 0px -115px 49px -10px rgba(255, 255, 255, 0.86) inset;
  -moz-box-shadow: 0px -115px 49px -10px rgba(255, 255, 255, 0.86) inset;
  z-index: 100; /* Position the shadow behind the content */
}
